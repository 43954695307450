/* eslint-disable no-return-assign */
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Calendar from 'react-calendar';
import { Doughnut } from 'react-chartjs-2';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import 'react-calendar/dist/Calendar.css';
import { Form } from '@unform/web';
import { getUTCDate, formatDate, returnTernaryResponse, mountOptionList } from 'functions';
import { debounce } from 'lodash';

import AutoAvaliacao from '~/assets/autoavaliacao.png';
import EmBreve from '~/assets/breve.jpg';
import Completed from '~/assets/completed.jpg';
import MyFeedback from '~/assets/feedback.jpg';
import { Select, MainContainer } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import {
  UpContainer,
  CardContainer,
  DescriptionContainer,
  CalendarContainer,
  ExpiredPeriodCardContainer,
  FormContainer,
} from './styles';

function EmployeeDashboard() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const cycle = useSelector((state) => state.cycle);

  const [evaluationCycleData, setEvaluationCycleData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeFinishedAutoavaliation, setEmployeeFinishedAutoavaliation] = useState(false);
  const [employeeHasDevelopmentPlan, setEmployeeHasDevelopmentPlan] = useState(false);
  const [cycleList, setCycleList] = useState([]);
  const [chartData, setChartData] = useState({});
  const formRef = useRef(null);

  const chartOptions = {
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: '', path: '/' },
      ]),
    );

    mountOptionList('evaluationcycles', setCycleList);
  }, [dispatch]);

  useEffect(() => {
    if (cycle.id && cycleList.length > 0 && formRef.current) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  useEffect(() => {
    async function getDates() {
      setLoading(true);
      if (!cycle.id) return;
      const { data: evaluationCycle } = await api.get(`evaluationcycles/${cycle.id}`);

      if (!evaluationCycle) {
        setShow(true);
        return;
      }
      setEvaluationCycleData(evaluationCycle);

      const { data: situation } = await api.get(`mysituation/${profile.id}/${evaluationCycle.id}`);

      if(situation.evaluationCycleAnswers?.filter((answer) => !answer.user_finished).length === 0){
        setEmployeeFinishedAutoavaliation(true);
      } else {
        setEmployeeFinishedAutoavaliation(false);
      }

      const { data: employee } = await api.get(`consolidatedevaluationcycledevelopmentplans/${cycle.id}`);
      setEmployeeHasDevelopmentPlan(employee.evaluationCycleDevelopmentPlans?.length > 0);
      const waiting = employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 0).length;
      const ended = employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length;
      const canceled = employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 2).length;
      const expired = employee.evaluationCycleDevelopmentPlans.filter(
        (plan) => Number(plan.status) !== 1 && Number(plan.status) !== 2 && new Date(plan.final_date) <= new Date(),
      ).length;

      setChartData({
        labels: ['Aguardando', 'Finalizado', 'Cancelado', 'Expirado'],
        datasets: [
          {
            label: 'Dados Principais',
            data: [waiting, ended, canceled, expired],
            backgroundColor: ['#411564', '#00995d', '#ed1651', '#f47920'],
            borderWidth: 2,
            offset: 10,
            hoverOffset: 4,
          },
        ],
      });

      setLoading(false);
    }

    getDates();
  }, [cycle.id, profile.id]);

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      },
      10,
      { trailing: true },
    ),
    [],
  );

  return (
    <MainContainer>
      <FormContainer>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Ciclo'
            label='Escolha o Ciclo de Avaliação'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
        </Form>
      </FormContainer>
      {!loading ? (
        <>
          <section>
            <DescriptionContainer>
              <div className='text-description'>
                <h1>Prezado(a) colaborador(a),</h1>
                <p>
                  É com satisfação que damos início ao nosso {cycleList.findIndex((cy) => cy.id === evaluationCycleData.id) + 1}º
                  ciclo do processo de Avaliação de Desempenho por Competências, referente ao período de{' '}
                  {evaluationCycleData?.description?.split('|')[1]}.
                </p>
                <p>
                  Alinhar expectativas individuais e organizacionais é parte fundamental do desenvolvimento de todos e evolução da
                  Unimed. Se conhecer e compreender o outro, obter e oferecer direcionamento, saber para onde ir e o que ajustar
                  gera confiança, promove o comprometimento e a motivação.
                </p>
                <p>Neste contexto, convidamos você a participar deste importante processo de gestão e desenvolvimento.</p>
                <p className='deadline'>
                  O prazo para conclusão do processo de auto avaliação é até{' '}
                  <i>{formatDate(evaluationCycleData?.final_employee_evaluation)}</i>.
                </p>
                <p>Boa avaliação a todos!</p>
                <cite>Gestão de Pessoas</cite>
                <p className='instruction-container'> Para responder as avaliações, clique abaixo:</p>
              </div>
            </DescriptionContainer>
          </section>
          <section>
            <UpContainer>
              <div>
                {getUTCDate(evaluationCycleData.initial_evaluation_period) < getUTCDate(new Date()) && (
                  <>
                    {employeeHasDevelopmentPlan && (
                      <CardContainer to={routes.EMPLOYEE_DEVELOPMENT_PLAN} backgroundcolor='#fff'>
                        <div className='card-title'>
                          <h2>Seu Plano Desenvolvimento</h2>
                          <MdKeyboardArrowRight size={24} color='#411564' />
                        </div>
                        <div className='pdi-chart-container'>
                          <Doughnut data={chartData} width={300} height={300} options={chartOptions} />
                        </div>
                      </CardContainer>
                    )}
                    {getUTCDate(evaluationCycleData?.initial_employee_evaluation) > getUTCDate(new Date()) && (
                      <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                        <div className='card-title'>
                          <h2>Autoavaliação - Em breve</h2>
                        </div>
                        <p>
                          Período de autoavaliação se inicia em{' '}
                          <i> {formatDate(evaluationCycleData?.initial_employee_evaluation)}</i>. Aguarde!
                        </p>
                        <span>
                          <img src={EmBreve} alt='' />
                        </span>
                      </ExpiredPeriodCardContainer>
                    )}
                    {getUTCDate(evaluationCycleData?.initial_employee_evaluation) <= getUTCDate(new Date()) &&
                      getUTCDate(evaluationCycleData?.final_employee_evaluation) >= getUTCDate(new Date()) &&
                      !employeeFinishedAutoavaliation && (
                        <CardContainer to={routes.EMPLOYEE_EVALUATION} backgroundcolor='#fff'>
                          <div className='card-title'>
                            <h2>Autoavaliação</h2>
                            <MdKeyboardArrowRight size={24} color='#411564' />
                          </div>
                          <p>Clique aqui e comece a preencher a sua avaliação de desempenho</p>
                          <span>
                            <img src={AutoAvaliacao} alt='' />
                          </span>
                        </CardContainer>
                      )}
                    {(employeeFinishedAutoavaliation ||
                      getUTCDate(evaluationCycleData?.final_employee_evaluation) < getUTCDate(new Date())) && (
                      <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                        <div className='card-title'>
                          <h2>Autoavaliação - Finalizado</h2>
                        </div>
                        <p>
                          {returnTernaryResponse(
                            employeeFinishedAutoavaliation,
                            'Você finalizou sua autoavaliação. ',
                            'Autoavaliação finalizado. ',
                          )}
                          Aguarde as instruções sobre as próximas etapas! Logo será possível acessar seu PDI.
                        </p>
                        <span>
                          <img src={Completed} alt='' />
                        </span>
                      </ExpiredPeriodCardContainer>
                    )}
                  </>
                )}

                <CardContainer to={`${routes.EMPLOYEE_TIMELINE}/${profile.id}`} backgroundcolor='#fff'>
                  <div className='card-title'>
                    <h2>Feedbacks Recebidos</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Acompanhe aqui os Feedbacks que você recebeu de seu líder.</p>
                  <span>
                    <img src={MyFeedback} alt='' width={210} />
                  </span>
                </CardContainer>
                <CalendarContainer backgroundcolor='#fff'>
                  <div className='card-title'>
                    <h2>Período Autoavaliação</h2>
                  </div>
                  <>
                    <Calendar
                      value={[
                        getUTCDate(evaluationCycleData?.initial_employee_evaluation),
                        getUTCDate(evaluationCycleData?.final_employee_evaluation),
                      ]}
                      onChange={() => null}
                      locale='pt-BR'
                    />
                  </>
                </CalendarContainer>
              </div>
            </UpContainer>
          </section>
        </>
      ) : (
        <>
          <UpContainer>
            <LoadingCard />
          </UpContainer>
          <UpContainer>
            <div>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </div>
          </UpContainer>
        </>
      )}
      <SweetAlert
        title='Não foi possível carregar o conteúdo'
        type='error'
        showCloseButton
        show={show}
        focusConfirmBtn
        showConfirm
        allowEscape
        onConfirm={() => setShow(false)}
      />
    </MainContainer>
  );
}

export default memo(EmployeeDashboard);
