export const AREA_EMPLOYEES = '/area-employees';
export const AREA_PDI = '/area-pdi';
export const BEHAVIOR = '/behavior';
export const CLASSIFICATION = '/classificacao';
export const COMMENTS = '/comment';
export const CONCILIATION = '/conciliation';
export const CONTINUOUS_FEEDBACK = '/continuous-feedback';
export const DASHBOARD = '/dashboard';
export const DEPARTMENT = '/department';
export const DEPARTMENT_CONTINUOUS_FEEDBACK = '/department-continuous-feedback';
export const DEPARTMENT_PDI = '/department-pdi';
export const DEVELOPMENT_PLAN = '/development-plan';
export const EMAIL = '/email';
export const EMPLOYEE_DASHBOARD = '/employee-dashboard';
export const EMPLOYEE_DEVELOPMENT_PLAN = '/employee-development-plan';
export const EMPLOYEE_EVALUATION = '/employee-evaluation';
export const EMPLOYEE_TIMELINE = '/employee-timeline';
export const EMPLOYEE_TRAINING = '/employee-training';
export const EVALUATION_CYCLE = '/evaluation-cycle';
export const FOLLOW = '/follow';
export const FORM = '/form';
export const HIERARCHY = '/hierarchy';
export const LEADER_DASHBOARD = '/leader-dashboard';
export const LEADER_EVALUATION = '/leader-evaluation';
export const MANAGER_FEEDBACK = '/manager-feedback';
export const PATH = '/path';
export const PDI_REPORT = '/pdi-report';
export const POSITION = '/position';
export const PROFILE = '/profile';
export const QUESTION = '/question';
export const RATING_SCALE = '/rating-scale';
export const RESULTS = '/results';
export const SKILL = '/skill';
export const SUMMARY_EVALUATION = '/summary-evaluations';
export const SUMMARY_EVALUATION_AREA = '/summary-evaluation-area';
export const SUMMARY_EVALUATION_EMPLOYEE = '/summary-evaluation-employee';
export const SYSTEM_CONFIG = '/sys-config';
export const TIMELINE = '/timeline';
export const TRAINING = '/training';
export const TRAINING_REQUEST = '/training-request';
export const UNFINISHED = '/unfinished';
export const USER = '/user';
export const VIDEO_TRAINING = '/video-training';
