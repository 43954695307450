import React, { memo, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

// import AnimatedNumber from 'animated-number-react';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateBreadcrumb } from '~/store/modules/user/actions';

import { LoadingCard } from '../../components/Shimmer';
import { Container, DescriptionContainer, EmployeesContainer, UpContainer, CardContainer } from './styles';

function ContinuousFeedback() {
  const dispatch = useDispatch();
  const process = useSelector((state) => state.user.process);

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: `Lista de Colaboradores - Feedback Contínuo`, path: '/continuous-feedback' },
      ]),
    );
  }, [dispatch, process]);

  const [employeeList, setEmployeeList] = useState([]);
  const [childrenAreas, setChildrenAreas] = useState([]);

  useEffect(() => {
    async function getData() {
      const { data: employees } = await api.get(`continuousfeedbackareaemployee/`);
      console.log("O que estou buscando?",employees)
      /*console.log("pegando dados", data)*/
      const sortedEmployees = employees.sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name, 'pt-BR'));
      const areas = sortedEmployees.map((employee) => employee.departments.name);
      const uniqueAreas = new Set([...areas]);
      const sortedAreas = [...uniqueAreas].sort((areaA, areaB) => areaA.localeCompare(areaB, 'pt-BR'));
      setEmployeeList(sortedEmployees);
      setChildrenAreas(sortedAreas);
    }

    getData();
  }, []);

  return (
    <Container>
      <DescriptionContainer>
        <section>
          <h1>Feedback Contínuo</h1>
          <span>
            Esta área serve para você registrar um histórico de feedbacks para seus colaboradores, sejam eles, feedbacks de
            melhoria ou feedbacks positivos.
          </span>
        </section>
      </DescriptionContainer>
      {childrenAreas.length > 0 ? (
        childrenAreas.map((area) => (
          <div key={area} className='area-description'>
            {childrenAreas.length > 1 ? <h3>{area}</h3> : <div />}
            <EmployeesContainer>
              <UpContainer>
                <div>
                  {employeeList.map((employee) => {
                    if (employee.departments.name === area) {
                      return (
                        <CardContainer
                          to={`${routes.TIMELINE}/${employee.id}`}
                          key={employee.id}
                          process={process}
                          withoutform={`${!employee.evaluationCycleAnswers?.length > 0}`}>
                          <section className='card-title'>
                            <h4>{employee.name}</h4>
                            <MdKeyboardArrowRight size={24} color='#333' />
                          </section>
                          <section className='employee-description'>
                            <span>
                              <b>HIERARQUIA</b> - {employee.hierarchies.description.toUpperCase()}
                            </span>
                            <span>
                              <b>CARGO</b> - {employee.positions.description.toUpperCase()}
                            </span>
                            <span>
                              <b>TRAJETÓRIA</b> - {employee.positions.paths.description.toUpperCase()}
                            </span>
                            <span>
                              <b>COLABORADOR(A)</b>{' '}
                              {formatDistance(parseISO(employee.admitted_at), new Date(), {
                                addSuffix: true,
                                locale: pt,
                              }).toUpperCase()}
                            </span>
                          </section>
                        </CardContainer>
                      );
                    }
                    return null;
                  })}
                </div>
              </UpContainer>
            </EmployeesContainer>
          </div>
        ))
      ) : (
        <EmployeesContainer>
          <UpContainer>
            <div>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </div>
          </UpContainer>
        </EmployeesContainer>
      )}
    </Container>
  );
}

export default memo(ContinuousFeedback);
