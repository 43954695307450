/* eslint-disable no-return-assign */
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Calendar from 'react-calendar';
import { Doughnut } from 'react-chartjs-2';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

import 'react-calendar/dist/Calendar.css';

import { debounce } from 'lodash';

import AutoAvaliacao from '~/assets/autoavaliacao.png';
import EmBreve from '~/assets/breve.jpg';
import Completed from '~/assets/completed.jpg';
import FeedbackContinuo from '~/assets/continuous_feedback.jpg';
import DevelopmentPlan from '~/assets/developmentPlan.png';
import EmployeeTraining from '~/assets/employee_training.jpg';
import MyFeedback from '~/assets/feedback.jpg';
import Gestor from '~/assets/leader-evaluation.jpg';
import ProfessionalTraining from '~/assets/professional-training.jpg';
import Resumo from '~/assets/reports.jpg';
import Feedback from '~/assets/talk.png';
import Training from '~/assets/training.jpg';
import { Select, DoughnutChart, MainContainer } from '~/components';
import { LoadingCard } from '~/components/Shimmer';
import { mountOptionList, getUTCDate, formatDate, groupBy, returnTernaryResponse } from '~/functions';
import * as routes from '~/routes/config';
import api from '~/services/api';
import { updateEvaluationCycleInUse } from '~/store/modules/cycle/actions';
import { updateBreadcrumb, updateProcessRequest, updateOptionsRequest } from '~/store/modules/user/actions';

import {
  UpContainer,
  CardContainer,
  DescriptionContainer,
  CalendarContainer,
  ExpiredPeriodCardContainer,
  DataSummaryContainer,
  ProgressBar,
  FormContainer,
} from './styles';

function Dashboard() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const cycle = useSelector((state) => state.cycle);
  const { roles } = profile ?? [];
  const formRef = useRef(null);

  const [evaluationCycleData, setEvaluationCycleData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departmentsCount, setDepartmentsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [autoEvaluation, setAutoEvaluation] = useState(0);
  const [leaderEvaluation, setLeaderEvaluation] = useState(0);
  const [feedbackFinished, setFeedbackFinished] = useState(0);
  const [departmentThatFinishedAnswers, setDepartmentThatFinishedAnswers] = useState(0);
  const [departmentThatFinishedLeaderAnswers, setDepartmentThatFinishedLeaderAnswers] = useState(0);
  const [departmentThatFinishedLeaderFeedback, setDepartmentThatFinishedLeaderFeedback] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [resultChartData, setResultChartData] = useState([]);
  const [employeeFinishedAutoavaliation, setEmployeeFinishedAutoavaliation] = useState(false);
  const [employeeConfirmFeedback, setEmployeeConfirmFeedback] = useState(0);
  const [employeeReceivedPdi, setEmployeeReceivedPdi] = useState(0);
  const [cycleList, setCycleList] = useState([]);

  const chartOptions = {
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { name: 'Início', path: '/' },
        { name: '', path: '/' },
      ]),
    );

    mountOptionList('evaluationcycles', setCycleList);
  }, [dispatch]);

  useEffect(() => {
    if (cycle.id && cycleList.length > 0 && formRef.current) {
      formRef.current.setData({ cycle_id: cycle.id });
    }
  }, [cycle.id, cycleList.length]);

  useEffect(() => {
    async function getDates() {
      setLoading(true);
      if (!cycle.id) return;
      const { data: evaluationCycle } = await api.get(`evaluationcycles/${cycle.id}`);

      if (!evaluationCycle) {
        setShow(true);
        return;
      }
      setEvaluationCycleData(evaluationCycle);

      const { data: situation } = await api.get(`mysituation/${profile.id}/${cycle.id}`);
      if(situation.evaluationCycleAnswers?.filter((answer) => !answer.user_finished).length === 0){
        setEmployeeFinishedAutoavaliation(true);
      } else {
        setEmployeeFinishedAutoavaliation(false);
      }

      if (roles?.includes('administrator')) {
        const { data: departments } = await api.get('departments');

        const { data: employeesData } = await api.get(`dashboardsummaries/${cycle.id}`);

        const totalDepartments = departments?.filter((depart) => depart.active && depart.__meta__?.users_count > 0);
        setDepartmentsCount(totalDepartments.length);

        const totalUsers = employeesData?.filter((user) => user.active);
        setUsersCount(totalUsers.length);

        const totalFinishedAutoEvaluation = employeesData?.filter(
          (employee) =>
            employee.__meta__.employeeEvaluationAnswers === 0 &&
            employee.__meta__.employeeEvaluationJustificatives === 0 &&
            employee.active,
        );
        setAutoEvaluation(totalFinishedAutoEvaluation.length);

        const totalFinishedLeaderEvaluation = employeesData?.filter(
          (employee) =>
            employee.__meta__.leaderEvaluationAnswers === 0 &&
            employee.__meta__.leaderEvaluationJustificatives === 0 &&
            employee.active,
        );
        setLeaderEvaluation(totalFinishedLeaderEvaluation.length);

        const totalFinishedFeedback = employeesData.filter(
          (employee) => employee.__meta__.leaderEvaluationComments === 0 && employee.active,
        );
        setFeedbackFinished(totalFinishedFeedback.length);

        const totalEmployeeConfirmFeedback = employeesData.filter(
          (employee) => employee.__meta__.employeeConfirmFeedback === 0 && employee.active,
        );
        setEmployeeConfirmFeedback(totalEmployeeConfirmFeedback.length);

        const totalEmployeeReceivedPdi = employeesData.filter(
          (employee) => employee.__meta__.employeeReceivedPdi !== 0 && employee.active,
        );
        setEmployeeReceivedPdi(totalEmployeeReceivedPdi.length);

        const totalUsersByDepartment = groupBy(totalUsers, 'department_id');
        const autoEvaluationCompletedByDepartment = groupBy(totalFinishedAutoEvaluation, 'department_id');
        const leaderEvaluationCompletedByDepartment = groupBy(totalFinishedLeaderEvaluation, 'department_id');
        const leaderFeedbackCompletedByDepartment = groupBy(totalFinishedFeedback, 'department_id');

        setDepartmentThatFinishedAnswers(0);
        setDepartmentThatFinishedLeaderAnswers(0);
        setDepartmentThatFinishedLeaderFeedback(0);
        Object.keys(totalUsersByDepartment).map((e) => {
          if (totalUsersByDepartment[e].length === autoEvaluationCompletedByDepartment[e]?.length) {
            setDepartmentThatFinishedAnswers((depart) => depart + 1);
          }
          if (totalUsersByDepartment[e].length === leaderEvaluationCompletedByDepartment[e]?.length) {
            setDepartmentThatFinishedLeaderAnswers((depart) => depart + 1);
          }
          if (totalUsersByDepartment[e].length === leaderFeedbackCompletedByDepartment[e]?.length) {
            setDepartmentThatFinishedLeaderFeedback((depart) => depart + 1);
          }
          return null;
        });
      }

      if (roles?.includes('evaluator')) {
        const { data: employees } = await api.get(`consolidatedevaluationcycledevelopmentplans/fakeId/${cycle.id}`);
        const waiting = employees
          .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 0).length)
          .reduce((a, b) => a + b, 0);
        const ended = employees
          .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 1).length)
          .reduce((a, b) => a + b, 0);
        const canceled = employees
          .map((employee) => employee.evaluationCycleDevelopmentPlans.filter((plan) => Number(plan.status) === 2).length)
          .reduce((a, b) => a + b, 0);
        const expired = employees
          .map(
            (employee) =>
              employee.evaluationCycleDevelopmentPlans.filter(
                (plan) => Number(plan.status) !== 1 && Number(plan.status) !== 2 && new Date(plan.final_date) <= new Date(),
              ).length,
          )
          .reduce((a, b) => a + b, 0);

        setChartData({
          labels: ['Aguardando', 'Finalizado', 'Cancelado', 'Expirado'],
          datasets: [
            {
              label: 'Dados Principais',
              data: [waiting, ended, canceled, expired],
              backgroundColor: ['#411564', '#00995d', '#ed1651', '#f47920'],
              borderWidth: 2,
              offset: 10,
              hoverOffset: 4,
            },
          ],
        });

        const { data: employeesAnswers } = await api.post('results', { type: 'leader', cycle: cycle.id });

        const withoutIncompletes = employeesAnswers.filter(
          (employee) =>
            employee.evaluationCycleAnswers.filter((answer) => answer.user_answer === null).length === 0 ||
            employee.evaluationCycleAnswers.filter((answer) => answer.leader_answer === null).length === 0,
        );

        const calculatedList = withoutIncompletes.map((employee) => ({
          id: employee.id,
          name: employee.name,
          department_id: employee.department_id,
          department_name: employee.departments.name,
          totalEmployee:
            employee.evaluationCycleAnswers.reduce((prev, cur) => {
              return prev + (cur.user_answer ?? 0);
            }, 0) / employee.evaluationCycleAnswers.length,
          totalLeader:
            employee.evaluationCycleAnswers.reduce((prev, cur) => {
              return prev + (cur.leader_answer ?? 0);
            }, 0) / employee.evaluationCycleAnswers.length,
          mainTotal:
            (employee.evaluationCycleAnswers.reduce((prev, cur) => {
              return prev + (cur.user_answer ?? 0);
            }, 0) /
              employee.evaluationCycleAnswers.length +
              employee.evaluationCycleAnswers.reduce((prev, cur) => {
                return prev + (cur.leader_answer ?? 0);
              }, 0) /
                employee.evaluationCycleAnswers.length) /
            2,
        }));

        let doesNotAttend = 0;
        let partiallyAttend = 0;
        let attend = 0;
        let overcomes = 0;

        const {
          data: { data: classifications },
        } = await api.get('classifications');

        const classificationsValue = classifications.reduce((ac, { description: x, ...rest }) => ((ac[x] = rest), ac), {});

        calculatedList.forEach((employee) => {
          const mainTotal = Number(employee.mainTotal).toFixed(2);

          if (
            mainTotal >= classificationsValue['Não Atende']?.initial_value &&
            mainTotal <= classificationsValue['Não Atende']?.final_value
          ) {
            doesNotAttend += 1;
          }
          if (
            mainTotal >= classificationsValue['Atende Parcialmente']?.initial_value &&
            mainTotal <= classificationsValue['Atende Parcialmente']?.final_value
          ) {
            partiallyAttend += 1;
          }
          if (mainTotal >= classificationsValue.Atende?.initial_value && mainTotal <= classificationsValue.Atende?.final_value) {
            attend += 1;
          }
          if (mainTotal >= classificationsValue.Supera?.initial_value && mainTotal <= classificationsValue.Supera?.final_value) {
            overcomes += 1;
          }
        });

        setResultChartData({
          labels: ['Não Atende', 'Atende Parcialmente', 'Atende', 'Supera'],
          datasets: [
            {
              label: 'Dados Principais',
              data: [Number(doesNotAttend), Number(partiallyAttend), Number(attend), Number(overcomes)],
              // backgroundColor: ['rgba(237,22,81,0.5)', 'rgba(255,203,8,0.5)', 'rgba(0,153,93,0.5)', 'rgba(10,95,85,0.5)'],
              backgroundColor: ['#ed1651', '#ffcb08', '#00995d', '#0a5f55'],
              borderWidth: 2,
              offset: 10,
              hoverOffset: 4,
            },
          ],
        });
      }

      setLoading(false);
    }

    getDates();
  }, [cycle.id, profile.id, roles]);

  const setProcess = (process) => {
    dispatch(updateProcessRequest(process));
  };

  const returnAllResultsHandler = () => {
    dispatch(updateOptionsRequest({ resultType: 'leader' }));
  };

  const submitForm = ({ cycle_id }) => {
    dispatch(updateEvaluationCycleInUse({ id: cycle_id }));
  };

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      },
      10,
      { trailing: true },
    ),
    [],
  );

  function getTextDepartmentPercentage(value) {
    return `${((value / departmentsCount) * 100).toFixed(2)}%`;
  }

  function getGeneralTextPercentage() {
    return (
      ((departmentThatFinishedAnswers + departmentThatFinishedLeaderAnswers + departmentThatFinishedLeaderFeedback) /
        (departmentsCount * 3)) *
      100
    ).toFixed(2);
  }

  return (
    <MainContainer>
      <FormContainer>
        <Form onSubmit={submitForm} onChange={handleChangeSubmit} className='form' ref={formRef}>
          <Select
            name='cycle_id'
            placeholder='Ciclo'
            label='Escolha o Ciclo de Avaliação'
            options={cycleList}
            isMulti={false}
            closeMenuOnSelect
            onChange={handleChangeSubmit}
          />
        </Form>
      </FormContainer>

      {!loading ? (
        <>
          {getUTCDate(evaluationCycleData.initial_evaluation_period) < getUTCDate(new Date()) && (
            <>
              {getUTCDate(evaluationCycleData?.final_employee_evaluation) > getUTCDate(new Date()) && (
                <section>
                  <DescriptionContainer>
                    <div className='text-description'>
                      <h1>Prezado(a) colaborador(a),</h1>
                      <p>
                        É com satisfação que damos início ao nosso{' '}
                        {cycleList.findIndex((cy) => cy.id === evaluationCycleData.id) + 1}º ciclo do processo de Avaliação de
                        Desempenho por Competências, referente ao período de {evaluationCycleData?.description?.split('|')[1]}.
                      </p>
                      <p>
                        Alinhar expectativas individuais e organizacionais é parte fundamental do desenvolvimento de todos e
                        evolução da Unimed. Se conhecer e compreender o outro, obter e oferecer direcionamento, saber para onde ir
                        e o que ajustar gera confiança, promove o comprometimento e a motivação.
                      </p>
                      <p>Neste contexto, convidamos você a participar deste importante processo de gestão e desenvolvimento.</p>
                      <p className='deadline'>
                        O prazo para conclusão do processo de auto avaliação é até{' '}
                        <i>{formatDate(evaluationCycleData?.final_employee_evaluation)}</i>.
                      </p>
                      <p>Boa avaliação a todos!</p>
                      <cite>Gestão de Pessoas</cite>
                      <p className='instruction-container'> Para responder as avaliações, clique abaixo:</p>
                    </div>
                  </DescriptionContainer>
                </section>
              )}
              {roles?.includes('administrator') && (
                <DataSummaryContainer>
                  <section className='department-container'>
                    <h6>Áreas</h6>
                    <div className='sub-department-container'>
                      <span>
                        <div>
                          <p>{departmentThatFinishedAnswers}</p>
                          <h2>|{departmentsCount}</h2>
                        </div>
                        <small className='description'>Auto</small>
                        <small>{getTextDepartmentPercentage(departmentThatFinishedAnswers)}</small>
                      </span>
                      <span>
                        <div>
                          <p>{departmentThatFinishedLeaderAnswers}</p>
                          <h2>|{departmentsCount}</h2>
                        </div>
                        <small className='description'>Gestor</small>
                        <small>{getTextDepartmentPercentage(departmentThatFinishedLeaderAnswers)}</small>
                      </span>
                      <span>
                        <div>
                          <p>{departmentThatFinishedLeaderFeedback}</p>
                          <h2>|{departmentsCount}</h2>
                        </div>
                        <small className='description'>Feedback</small>
                        <small>{getTextDepartmentPercentage(departmentThatFinishedLeaderFeedback)}</small>
                      </span>
                    </div>
                    <ProgressBar percentage={getGeneralTextPercentage()}>
                      <div>{getGeneralTextPercentage()}%</div>
                    </ProgressBar>
                  </section>
                  <Link to={`${routes.UNFINISHED}/auto-evaluation`}>
                    <section>
                      <h6>Autoavaliação</h6>
                      <DoughnutChart
                        total={usersCount}
                        value={autoEvaluation}
                        width='142px'
                        color='#0a5f55'
                        strokeColor='#5c5c65'
                      />
                    </section>
                  </Link>
                  <Link to={`${routes.UNFINISHED}/leader-evaluation`}>
                    <section>
                      <h6>Avaliação dos Gestores</h6>
                      <DoughnutChart
                        total={usersCount}
                        value={leaderEvaluation}
                        width='142px'
                        color='#f47920'
                        strokeColor='#5c5c65'
                      />
                    </section>
                  </Link>
                  <Link to={`${routes.UNFINISHED}/feedback`}>
                    <section>
                      <h6>Feedback</h6>
                      <DoughnutChart
                        total={usersCount}
                        value={feedbackFinished}
                        width='142px'
                        color='#ed1651'
                        strokeColor='#5c5c65'
                      />
                    </section>
                  </Link>
                  <Link to={`${routes.UNFINISHED}/feedback-received`}>
                    <section>
                      <h6>Feedback Confirmado</h6>
                      <DoughnutChart
                        total={usersCount}
                        value={employeeConfirmFeedback}
                        width='142px'
                        color='#a3238e'
                        strokeColor='#5c5c65'
                      />
                    </section>
                  </Link>
                  <Link to={`${routes.UNFINISHED}/pdi-received`}>
                    <section>
                      <h6>PDI Criados</h6>
                      <DoughnutChart
                        total={usersCount}
                        value={employeeReceivedPdi}
                        width='142px'
                        color='#b1d34b'
                        strokeColor='#5c5c65'
                      />
                    </section>
                  </Link>
                </DataSummaryContainer>
              )}
              <section>
                <UpContainer>
                  <div>
                    <CardContainer to={routes.EMPLOYEE_DEVELOPMENT_PLAN} backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Seu Plano de Desenvolvimento</h2>
                        <MdKeyboardArrowRight size={24} color='#411564' />
                      </div>
                      <p>Clique aqui para acessar o seu plano de desenvolvimento</p>
                      <span>
                        <img src={DevelopmentPlan} alt='' />
                      </span>
                    </CardContainer>
                    {roles?.includes('evaluated') && (
                      <>
                        {getUTCDate(evaluationCycleData?.initial_employee_evaluation) > getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Autoavaliação - Em breve</h2>
                            </div>
                            <p>
                              Período de autoavaliação se inicia em{' '}
                              <i> {formatDate(evaluationCycleData?.initial_employee_evaluation)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_employee_evaluation) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_employee_evaluation) >= getUTCDate(new Date()) &&
                          !employeeFinishedAutoavaliation && (
                            <CardContainer to={routes.EMPLOYEE_EVALUATION} backgroundcolor='#fff'>
                              <div className='card-title'>
                                <h2>Autoavaliação</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>Clique aqui e comece a preencher a sua avaliação de desempenho</p>
                              <span>
                                <img src={AutoAvaliacao} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {(employeeFinishedAutoavaliation ||
                          getUTCDate(evaluationCycleData?.final_employee_evaluation) < getUTCDate(new Date())) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Autoavaliação - Finalizada</h2>
                            </div>
                            <p>
                              {returnTernaryResponse(
                                employeeFinishedAutoavaliation,
                                'Você finalizou sua autoavaliação. ',
                                'Autoavaliação finalizado. ',
                              )}
                              Aguarde as instruções sobre as próximas etapas! Logo será possível acessar seu PDI.
                            </p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                      </>
                    )}
                    {roles?.includes('evaluator') && (
                      <>
                        {getUTCDate(evaluationCycleData?.initial_manager_evaluation) >= getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Avaliação do Gestor</h2>
                            </div>
                            <p>
                              Você poderá preencher a avaliação dos seus liderados a partir de{' '}
                              <i> {formatDate(evaluationCycleData?.initial_manager_evaluation)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_evaluation) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_manager_evaluation) >= getUTCDate(new Date()) && (
                            <CardContainer
                              to={routes.AREA_EMPLOYEES}
                              backgroundcolor='#fff'
                              onClick={() => setProcess('evaluation')}>
                              <div className='card-title'>
                                <h2>Avaliação do Gestor</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>Clique aqui e comece a preencher a avaliação de desempenho dos seus liderados</p>
                              <span>
                                <img src={Gestor} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {getUTCDate(evaluationCycleData?.final_manager_evaluation) < getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Avaliação do Gestor - Finalizado</h2>
                            </div>
                            <p>
                              Período de avaliação finalizado. O período para você preencher e dar o Feedback para seus
                              colaboradores já deve estar disponível!
                            </p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_feedback) >= getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Feedback do Gestor - Em breve</h2>
                            </div>
                            <p>
                              O Feedback do gestor se inicia em{' '}
                              <i> {formatDate(evaluationCycleData?.initial_manager_feedback)}</i>. Aguarde!
                            </p>
                            <span>
                              <img src={EmBreve} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        {getUTCDate(evaluationCycleData?.initial_manager_feedback) <= getUTCDate(new Date()) &&
                          getUTCDate(evaluationCycleData?.final_manager_feedback) >= getUTCDate(new Date()) && (
                            <CardContainer
                              to={routes.AREA_EMPLOYEES}
                              backgroundcolor='#fff'
                              onClick={() => setProcess('feedback')}>
                              <div className='card-title'>
                                <h2>Feedback do Gestor</h2>
                                <MdKeyboardArrowRight size={24} color='#411564' />
                              </div>
                              <p>
                                Clique aqui e comece a preencher o Feedback e cadastrar o PDI para seus liderados. Aqui também
                                você pode confirmar que deu o Feedback para seus colaboradores.
                              </p>
                              <span>
                                <img src={Feedback} alt='' />
                              </span>
                            </CardContainer>
                          )}
                        {getUTCDate(evaluationCycleData?.final_manager_feedback) < getUTCDate(new Date()) && (
                          <ExpiredPeriodCardContainer backgroundcolor='#fff'>
                            <div className='card-title'>
                              <h2>Feedback do Gestor - Finalizado</h2>
                            </div>
                            <p>Período de feedback finalizado. Aguarde sobre as próximas etapas!</p>
                            <span>
                              <img src={Completed} alt='' />
                            </span>
                          </ExpiredPeriodCardContainer>
                        )}
                        <CardContainer to={routes.AREA_PDI} backgroundcolor='#fff'>
                          <div className='card-title'>
                            <h2>PDI</h2>
                            <MdKeyboardArrowRight size={24} color='#411564' />
                          </div>
                          <div className='pdi-chart-container'>
                            <Doughnut data={chartData} width={300} height={300} options={chartOptions} />
                          </div>
                        </CardContainer>
                        <CardContainer to={routes.RESULTS} onClick={returnAllResultsHandler} backgroundcolor='#fff'>
                          <div className='card-title'>
                            <h2>Resultados</h2>
                            <MdKeyboardArrowRight size={24} color='#411564' />
                          </div>
                          <div className='pdi-chart-container'>
                            <Doughnut data={resultChartData} width={300} height={300} options={chartOptions} />
                          </div>
                        </CardContainer>
                      </>
                    )}
                  </div>
                </UpContainer>
              </section>
            </>
          )}

          <section>
            <UpContainer>
              <div>
                <CardContainer to={routes.CONTINUOUS_FEEDBACK} backgroundcolor='#fff' onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Feedback Contínuo</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>
                    Essa área é pra você registrar os acontecimentos do dia a dia, e ter um histórico de Feedbacks Positivos ou de
                    Desenvolvimentos, bem como de ações sugeridas
                  </p>
                  <span>
                    <img src={FeedbackContinuo} alt='' style={{ width: '180px !important' }} />
                  </span>
                </CardContainer>
                <CardContainer
                  to={`${routes.EMPLOYEE_TIMELINE}/${profile.id}`}
                  backgroundcolor='#fff'
                  onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Feedbacks Recebidos</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Acompanhe aqui os Feedbacks que você recebeu de seu líder.</p>
                  <span>
                    <img src={MyFeedback} alt='' width={210} />
                  </span>
                </CardContainer>
                {roles?.includes('administrator') && (
                  <>
                    <CardContainer to={routes.SUMMARY_EVALUATION} backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Resumo das Avaliações</h2>
                        <MdKeyboardArrowRight size={24} color='#411564' />
                      </div>
                      <p>Clique aqui e acesse o resumo das avaliações, gráficos indicadores de desempenho</p>
                      <span>
                        <img src={Resumo} alt='' />
                      </span>
                    </CardContainer>
                  </>
                )}
                <CardContainer
                  to={`${routes.TRAINING}/${profile.id}`}
                  backgroundcolor='#fff'
                  onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Treinamentos</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Acompanhamento de Treinamentos por equipe e individual.</p>
                  <span>
                    <img src={Training} alt='' width={210} />
                  </span>
                </CardContainer>
                <CardContainer
                  to={`${routes.TRAINING_REQUEST}/${profile.id}`}
                  backgroundcolor='#fff'
                  onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Treinamentos da Minha Equipe</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Este espaço é para você solicitar e acompanhar os treinamentos de sua equipe.</p>
                  <span>
                    <img src={ProfessionalTraining} alt='' width={210} />
                  </span>
                </CardContainer>
                <CardContainer to={routes.EMPLOYEE_TRAINING} backgroundcolor='#fff' onClick={() => setProcess('feedback')}>
                  <div className='card-title'>
                    <h2>Meus Treinamentos</h2>
                    <MdKeyboardArrowRight size={24} color='#411564' />
                  </div>
                  <p>Veja aqui todos os treinamentos que você tem agendado</p>
                  <span>
                    <img src={EmployeeTraining} alt='' width={210} />
                  </span>
                </CardContainer>
                <CalendarContainer backgroundcolor='#fff'>
                  <div className='card-title'>
                    <h2>Período Autoavaliação</h2>
                  </div>
                  <>
                    <Calendar
                      value={[
                        getUTCDate(evaluationCycleData?.initial_employee_evaluation),
                        getUTCDate(evaluationCycleData?.final_employee_evaluation),
                      ]}
                      onChange={() => null}
                      locale='pt-BR'
                    />
                  </>
                </CalendarContainer>
                {roles?.includes('evaluator') && (
                  <>
                    <CalendarContainer backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Período Avaliação do Gestor</h2>
                      </div>
                      <>
                        <Calendar
                          value={[
                            getUTCDate(evaluationCycleData?.initial_manager_evaluation),
                            getUTCDate(evaluationCycleData?.final_manager_evaluation),
                          ]}
                          onChange={() => null}
                          locale='pt-BR'
                        />
                      </>
                    </CalendarContainer>
                    <CalendarContainer backgroundcolor='#fff'>
                      <div className='card-title'>
                        <h2>Período Feedback do Gestor</h2>
                      </div>
                      <>
                        <Calendar
                          value={[
                            getUTCDate(evaluationCycleData?.initial_manager_feedback),
                            getUTCDate(evaluationCycleData?.final_manager_feedback),
                          ]}
                          onChange={() => null}
                          locale='pt-BR'
                        />
                      </>
                    </CalendarContainer>
                  </>
                )}
              </div>
            </UpContainer>
          </section>
        </>
      ) : (
        <>
          <UpContainer>
            <LoadingCard />
          </UpContainer>
          <UpContainer>
            <div>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </div>
          </UpContainer>
        </>
      )}
      <SweetAlert
        title='Não foi possível carregar o conteúdo. Verifique a data de Início e Fim do ciclo.'
        type='error'
        showCloseButton
        show={show}
        focusConfirmBtn
        showConfirm
        allowEscape
        onConfirm={() => setShow(false)}
        confirmBtnCssClass='delete-confirm-button'
      />
    </MainContainer>
  );
}

export default memo(Dashboard);
